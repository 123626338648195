import { ref, nextTick } from 'vue'
import useTableHeight from "@/hooks/useTableHeight";
const useHandleCollapse = () => {
    const { tableHeight  } = useTableHeight();
    // 折叠控制
    const isOpen = ref(false)

    const handleCollapse = () => {
        isOpen.value = !isOpen.value
        nextTick(() => {
            const tablePosition = useTableHeight(true, '.pagination-container', true);
            tableHeight.value = tablePosition.tableHeight.value
        })
    }
    return {
        handleCollapse,
        isOpen
    }
}
export default useHandleCollapse