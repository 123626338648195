
import GC from '@grapecity/spread-sheets'

interface HitInfo {
    x: number;
    y: number;
    row: any;
    col: any;
    cellStyle: any;
    cellRect: any;
    sheetArea: any;
    sheet: any;
}
interface HeaderProps {
    name: string;
    formal?: string;
}

class TipCellType extends GC.Spread.Sheets.CellTypes.ColumnHeader {
    header: HeaderProps[];
    tipText: string;
    setId: string;
    top: number;
    toolTipElement?: HTMLDivElement;


    constructor(header: HeaderProps[], tipText: string, setId: string, top: number, toolTipElement: any = null) {
        super();
        this.header = header;
        this.tipText = tipText;
        this.setId = setId
        this.top = top
        toolTipElement = null;
    }


    getHitInfo(x: number, y: number, cellStyle: any, cellRect: any, context: any): HitInfo {
        return {
            x: x,
            y: y,
            row: context.row,
            col: context.col,
            cellStyle: cellStyle,
            cellRect: cellRect,
            sheetArea: context.sheetArea,
            sheet: context.sheet
        };
    }

    processMouseEnter(hitinfo: HitInfo): boolean {
        if (!this.header[hitinfo.col]) {
            return false;
        }
        if (!this.toolTipElement) {
            const div = document.createElement("div");
            div.style.position = 'absolute';
            div.style.border = '1px #C0C0C0 solid';
            div.style.boxShadow = '1px 2px 5px rgba(0,0,0,0.4)';
            div.style.font = '12px';
            div.style.background = 'white';
            div.style.padding = '5px';
            div.style.whiteSpace = 'nowrap'
            div.innerText = this.tipText;
            this.toolTipElement = div;
        }

        this.toolTipElement.style.left = hitinfo.x + 'px';
        this.toolTipElement.style.transform = 'translateX(-50%)';
        this.toolTipElement.style.display = 'block';
        (document.querySelector(this.setId) as Element).appendChild(this.toolTipElement);
        this.toolTipElement.style.top =  this.top + 'px';
        const toolTipElementWidth = this.toolTipElement.offsetWidth;
        const totalWidth = hitinfo.sheet.Zs.width;
        if (hitinfo.x + toolTipElementWidth / 2 > totalWidth) {
            const bounds: number = totalWidth - (hitinfo.x + toolTipElementWidth / 2) || 0;
            this.toolTipElement.style.left = hitinfo.x + bounds + 'px';
        }
        return true;
    }

    processMouseLeave(hitInfo: HitInfo): boolean {
        if (this.toolTipElement) {
            this.toolTipElement.style.display = 'none';
        }
        return true;
    }
}
const useHeaderTip = (sheet: GC.Spread.Sheets.Worksheet, headerArr: HeaderProps[][], setId: string, top: number) => {
    for (let row = 0; row < headerArr.length; row++) {
        for (let col = 0; col < headerArr[row].length; col++) {
            //设置固定字段的tip提示
            if (headerArr[row][col].formal) {
                // sheet.setCellType(row, col, new GC.Spread.Sheets.CellTypes.ColumnHeader(), GC.Spread.Sheets.SheetArea.colHeader)
                sheet.setCellType(row, col, new TipCellType(headerArr[1], headerArr[row][col].formal as string, setId, top), GC.Spread.Sheets.SheetArea.colHeader);
            }
        }
    }
}
export default useHeaderTip